html, #root, .App {
  height: 100%;
  width: 100%;
  max-height: 100%;
}

body {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #718D47;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 1.3em;
  font-size: large;
}

body #container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body .header {
  border-color: #7f9e50 !important;
}

body .border-1 {
  border-color: #647D3F;
}

body .dark-mode {
  color: #f3fff8;
}

body .bg-evolution-start {
  background: #ed9f9f;
  background: -webkit-gradient(linear, left top, right top, from(#ed9f9f), color-stop(70%, #ede19f));
  background: linear-gradient(90deg, #ed9f9f 0%, #ede19f 70%);
}

body .bg-evolution-mid {
  background: #ed9f9f;
  background: -webkit-gradient(linear, left top, right top, from(#ed9f9f), color-stop(70%, #ede19f), color-stop(95%, #b9ea9f));
  background: linear-gradient(90deg, #ed9f9f 0%, #ede19f 70%, #b9ea9f 95%);
}

body .bg-evolution-end {
  background: #ed9f9f;
  background: -webkit-gradient(linear, left top, right top, from(#ed9f9f), color-stop(35%, #ede19f), color-stop(77%, #b9ea9f), to(#9ac4e4));
  background: linear-gradient(90deg, #ed9f9f 0%, #ede19f 35%, #b9ea9f 77%, #9ac4e4 100%);
}

body .camera {
  height: 100%;
}

body .instruction {
  font-size: 1.2em;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
}

body .lead {
  color: #D5E492;
  text-transform: uppercase;
}

body h3, body h4, body h5, body h6, body .form-label, body .input-group-text {
  color: #D5E492;
  font-weight: 700;
  letter-spacing: 0.2em;
  font-size: medium;
  text-transform: uppercase;
}

body h1 {
  color: #D5E492;
  text-transform: uppercase;
}

body h2 {
  color: #D5E492;
  text-transform: uppercase;
}

body table td {
  padding: 0.6em;
}

body table.text-light td {
  border: 1px solid #f3fff8;
}

body .text-dark h2, body .text-dark h3, body .text-dark h4, body .text-dark h5, body .text-dark h6 {
  color: #647D3F;
}

body .disabled {
  opacity: 0.3;
}

body strong {
  font-weight: 700;
}

body .border-primary {
  border-color: #D5E492;
}

body .profile {
  background-color: #718D47;
}

body .progress {
  background-color: transparent;
}

body .module-nav .module-item {
  padding: 1em;
  text-align: center;
  border: 1px solid #D5E492;
  background-color: transparent;
  border-radius: 1em;
  margin-bottom: 1em;
  min-height: 320px;
  border-color: rgba(213, 228, 146, 0.4);
}

body .module-nav .module-item[aria-expanded="true"], body .module-nav .module-item:hover {
  border-color: #D5E492;
  opacity: 0.8;
}

body .dark-mode .module-nav .module-item {
  color: #f3fff8;
}

body .react-tabs .nav-item {
  text-align: center;
}

body .react-tabs__tab--selected {
  background-color: #647D3F;
  color: #f3fff8;
  border-bottom: 1px solid #647D3F;
}

body .nav-menu {
  text-transform: uppercase;
  font-size: 80%;
}

body .nav-menu .accordion-button::after {
  background-image: url("../Modules/Common/img/icon-chevron-compact-down.svg");
}

body .nav-menu .accordion-header {
  border: 0;
}

body .nav-menu .accordion-body {
  padding-top: 0;
}

body .nav-menu .accordion-button, body .nav-menu .accordion-item {
  background-color: transparent;
  border: 0;
  color: #D5E492;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-transform: uppercase;
}

body .nav-menu .accordion-button a, body .nav-menu .accordion-item a {
  text-decoration: none;
}

body .nav-menu .accordion-button .active, body .nav-menu .accordion-item .active {
  color: #f3fff8;
  text-decoration: none;
}

body .nav-menu .accordion-button .border-start, body .nav-menu .accordion-item .border-start {
  border-color: #D5E492 !important;
}

body .input-overlay {
  width: 25%;
  position: absolute;
  padding: 1em;
}

body .input-overlay input {
  background-color: rgba(243, 255, 248, 0.2);
  border-radius: 5em;
  text-align: center;
  max-width: 5em;
}

body .input-overlay label {
  background-color: rgba(100, 125, 63, 0.3);
  margin-top: 1px;
}

body .user-ready {
  position: absolute;
  padding: 1em;
  z-index: 10;
}

body .form-big {
  padding: 1em;
}

body .form-big .list-group-item {
  background-color: rgba(213, 228, 146, 0.2);
  border-color: #D5E492;
}

body .form-big .form-check.tests {
  padding: 1.2em 2.2em 0.6em 2em;
}

body .form-big .form-check.tests ul {
  font-size: 75%;
}

body .form-big .form-check {
  padding: 0.6em 0.9em 0.6em 2em;
  font-size: 1.2em;
  display: inline-block;
  border: 3px solid #D5E492;
  border-radius: 3em;
  margin: 0.3em;
  color: #f3fff8;
}

body .form-big .form-check:hover, body .form-big .form-check:focus {
  background-color: rgba(213, 228, 146, 0.7);
}

body .form-big .form-check label {
  padding-top: 0.2em;
}

body .form-big .input-group {
  margin-bottom: 1em;
}

body .form-big .form-label {
  text-transform: uppercase;
  min-width: 25%;
}

body .form-big .btn-group .btn {
  border-width: 3px;
}

body .form-big .form-control {
  border-radius: 1em !important;
  border: 3px solid #D5E492;
  background-color: transparent;
  color: #f3fff8;
  font-size: 1.3em;
}

body .form-big .form-control::-webkit-input-placeholder {
  color: #D5E492;
  font-style: italic;
  font-size: 0.85em;
}

body .form-big .form-control:-ms-input-placeholder {
  color: #D5E492;
  font-style: italic;
  font-size: 0.85em;
}

body .form-big .form-control::-ms-input-placeholder {
  color: #D5E492;
  font-style: italic;
  font-size: 0.85em;
}

body .form-big .form-control::placeholder {
  color: #D5E492;
  font-style: italic;
  font-size: 0.85em;
}

body .form-big .form-control:focus {
  border-color: #f3fff8;
}

body .form-big .input-group-text {
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  color: #D5E492;
}

body .search .form-control {
  height: 3em;
}

body .search .btn {
  height: 3em;
}

body a, body .nav-link, body .btn-link {
  color: #cddf7e;
}

body a:focus, body a:hover, body .nav-link:focus, body .nav-link:hover, body .btn-link:focus, body .btn-link:hover {
  color: #b6d040;
}

body .dropdown-item:focus, body .dropdown-item:hover {
  background-color: #f1bf8d;
}

body .list-group-item.active, body .form-check-input:checked, body .dropdown-item.active, body .dropdown-item:active {
  background-color: #eca55f;
  border-color: #eca55f;
  color: #495b2e;
}

body .nav-tabs {
  border-color: transparent;
}

body .nav-tabs .nav-item {
  margin-right: 0.6em;
}

body .nav-tabs .nav-link {
  color: #dde9a6;
}

body .nav-tabs .nav-link:hover {
  border-color: #D5E492;
  color: #D5E492;
}

body .nav-tabs .nav-item.show .nav-link, body .nav-tabs .nav-link.active {
  background-color: transparent;
  color: #D5E492;
  border-color: #c5da69;
}

body .exercise .card {
  min-width: 300px;
}

body .exercise-list {
  z-index: 10;
}

body .exercise-list .btn {
  min-height: 9em;
  width: 100%;
}

body .exercise-list .btn-check:active + .btn, body .exercise-list .btn-check:checked + .btn, body .exercise-list .btn.active, body .exercise-list .btn.show, body .exercise-list .btn:active {
  background-color: rgba(243, 255, 248, 0.3);
  color: #f3fff8;
}

body .card {
  border-radius: 1em;
}

body .card .btn-close {
  background-color: #c5da69;
  opacity: 1;
  padding: 0.6em;
  position: absolute;
  margin-right: -1em;
  margin-top: -1em;
}

body .animate .card:hover {
  -webkit-box-shadow: #647D3F 0.3em 0.3em 1em;
          box-shadow: #647D3F 0.3em 0.3em 1em;
  -webkit-transform: translate(-0.2em, -0.2em);
          transform: translate(-0.2em, -0.2em);
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
}

body .card-img, body .card-img-top {
  border-radius: 1em 1em 0 0;
}

body .card.profile, body .dark-mode .card {
  background-color: #6c8644;
  border-color: #7f9e50;
  z-index: 20;
}

body .display-1, body .display-2, body .display-3, body .display-4, body .display-5 {
  font-weight: 100;
  line-height: 1.3em;
  margin-bottom: 0.3em;
}

body .text-muted {
  color: #8cac5b !important;
}

body p {
  margin-bottom: 1em;
}

body dl {
  font-style: italic;
  font-size: 1.6em;
  line-height: 1.2em;
  border-right: 1px solid #718D47;
  padding-right: 1em;
}

body dl dd {
  padding-left: 1em;
}

body .lead {
  font-size: 1.2em;
  line-height: 1.2em;
}

body .btn {
  border-radius: 2em;
  padding: .6em 1.6em;
  letter-spacing: 0.03em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

body .btn.rounded-circle {
  padding: 1em;
}

body .btn-outline-primary {
  color: #dde9a6;
  border-color: #dde9a6;
}

body .btn-outline-primary:hover, body .btn-outline-primary:focus {
  background-color: rgba(213, 228, 146, 0.2);
  border-color: #cddf7e;
  color: #D5E492;
}

body .btn-outline-light:hover, body .btn-outline-light:focus {
  background-color: rgba(243, 255, 248, 0.2);
  color: #f3fff8;
}

body .bg-dark {
  background-color: #647D3F !important;
}

body .bg-success {
  background-color: #b9ea9f !important;
  color: #647D3F;
}

body .bg-warning {
  background-color: #ede19f !important;
  color: #647D3F;
}

body .bg-danger {
  background-color: #ed9f9f !important;
  color: #647D3F;
}

body .bg-mid {
  background-color: #718D47;
}

body .bg-right {
  background-color: #f0a0a0;
}

body .bg-left {
  background-color: #9bc8e6;
}

body .bg-black {
  background-color: #000;
}

body .btn-primary {
  background-color: #c5da69;
  border-color: #c5da69;
  color: #3b4a25;
}

body .btn-primary:hover, body .btn-primary:focus {
  background-color: #7f9f50;
  border-color: #D5E492;
  color: #D5E492;
}

body .btn-primary.disabled, body .btn-primary.btn.disabled, body .btn-primary.btn:disabled {
  background-color: #D5E492;
  border-color: #D5E492;
}

body .btn-secondary {
  background-color: #eca55f;
  border-color: #eca55f;
}

body .btn-secondary:hover, body .btn-secondary:focus {
  background-color: rgba(236, 165, 95, 0.2);
  border-color: #e99848;
  color: #eca55f;
}

body .btn-light {
  color: #b6d040;
}

body .btn-light:hover, body .btn-light:focus {
  color: #f3fff8;
  background-color: rgba(243, 255, 248, 0.2);
}

body .bg-primary {
  background-color: #D5E492 !important;
}

body .bg-secondary {
  background-color: #eca55f !important;
}

body .bg-muted {
  background-color: #718D47 !important;
}

body .border-primary {
  border-color: #D5E492 !important;
}

body .carousel-caption {
  background-color: #647D3F;
}

body .banner {
  background-color: #647D3F;
}

body .x-small {
  font-size: 0.75em;
}

body .xx-small {
  font-size: 0.5em;
}

body .carousel-control-next-icon, body .carousel-control-prev-icon {
  width: 4rem;
  height: 4rem;
  color: #D5E492;
  background-color: #647D3F;
  padding: 1em 0.3em;
}

body .carousel-indicators [data-bs-target] {
  height: 1em;
  width: 1em;
  margin: 0.3em;
  border-radius: 100%;
}

body .carousel-caption {
  padding-bottom: 2em;
}

body .body-age {
  text-align: center;
  display: inline-block;
}

body .body-age p {
  margin: 2px 0 0;
}

body .body-age .label {
  text-transform: uppercase;
  font-size: 50%;
}

body .body-age .counter {
  background: no-repeat center center;
  background-size: contain;
  padding: 2em;
  min-width: 12em;
}

body .body-age .counter .age {
  font-size: xx-large;
}

body .body-age .counter .badge {
  margin-top: 0.3em;
}

body .animate .card:hover {
  -webkit-transform: translate(0, -0.3em);
          transform: translate(0, -0.3em);
  -webkit-box-shadow: 0 0.1rem 0.3rem rgba(47, 50, 91, 0.15) !important;
          box-shadow: 0 0.1rem 0.3rem rgba(47, 50, 91, 0.15) !important;
  -webkit-transition: 600ms;
  transition: 600ms;
}

body .scrolling-wrapper-flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

body .scrolling-wrapper-flexbox::-webkit-scrollbar {
  display: none;
}

body .feather-heart:hover, body .feather-heart.active {
  fill: rgba(221, 14, 14, 0.842);
}

body .exercise .card {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: .5em 1em;
  width: 18em;
}

body .exercise .card .card-header {
  padding: 1.6em 1.6em .6em;
}

body .exercise .card .card-body {
  padding: 1.6em;
}

body .hide {
  opacity: 0;
  -webkit-animation-duration: 450ms;
          animation-duration: 450ms;
}

body .hidden {
  display: none;
}

body .pain {
  position: absolute;
  right: 10em;
  cursor: move;
  z-index: 10;
  width: 3em;
  top: 3em;
}

body .cGTXwG svg {
  fill: #D5E492;
}

body .cGTXwG svg:hover path {
  fill: #eca55f;
}

body .cGTXwG svg.selected path {
  fill: rgba(245, 87, 87, 0.9);
}

body .flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

body .em-6 {
  max-width: 6em !important;
}

body .em-12 {
  max-width: 12em !important;
}

@media (min-width: 1200px) {
  .camera {
    width: 80%;
  }
  #secondary-button {
    position: absolute;
  }
}

@media (max-width: 1200px) {
  .navbar-collapse {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
  }
}
